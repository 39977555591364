<template>
  <div>
    <div
      v-if="$can('create', 'licensing')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="createLicensing()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <div class="d-flex align-items-center">
      <b-input-group class="input-group-merge round search-input-filter mr-3">
        <b-input-group-prepend
          is-text
        >
          <VSIcons
            name="Search"
            color="#7D8E9E"
            width="18"
            height="18"
          />
        </b-input-group-prepend>
        <b-form-input
          v-model="filterKanban"
          placeholder="Buscar..."
        />
      </b-input-group>
      <ModeViewAlternate module="licensing" />
    </div>
    <b-row class="mt-4">
      <b-col md="3">
        <h5>Instrução</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="instructionList"
            @add="saveChanges('Instrução')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in instructionList"
                :key="card.id"
                tag="li"
              >
                <LicensingkanbanCard
                  v-if="card.licensing_name ? card.licensing_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="instruction"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!instructionList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </b-col>
      <b-col md="3">
        <h5>Protocolo</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="protocolList"
            @add="saveChanges('Protocolo')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in protocolList"
                :key="card.id"
                tag="li"
              >
                <LicensingkanbanCard
                  v-if="card.licensing_name ? card.licensing_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="protocol"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!protocolList.length > 0">
            <span

              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </b-col>
      <b-col md="3">
        <h5>Em análise</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="analiseList"
            @add="saveChanges('Em análise')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in analiseList"
                :key="card.id"
                tag="li"
              >
                <LicensingkanbanCard
                  v-if="card.licensing_name ? card.licensing_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="analise"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!analiseList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </b-col>
      <b-col md="3">
        <h5>Concluído</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="concludedList"
            @add="saveChanges('Concluído')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in concludedList"
                :key="card.id"
                tag="li"
              >
                <LicensingkanbanCard
                  v-if="card.licensing_name ? card.licensing_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="concluded"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!concludedList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BListGroupItem,
  VBToggle,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ModeViewAlternate from '@/components/mode-view-alternate/ModeViewAlternate.vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import LicensingkanbanCard from './LicensingKanbanCard.vue'
import licensing from '@/services/licensing'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroupItem,
    draggable,
    LicensingkanbanCard,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    VSIcons,
    ModeViewAlternate,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      filterKanban: '',
      instructionList: [],
      concludedList: [],
      analiseList: [],
      protocolList: [],
      itemToUpdate: {},
    }
  },
  created() {
    this.instructionList = this.items.filter(arr => arr.step === 'Instrução')
    this.concludedList = this.items.filter(arr => arr.step === 'Concluído')
    this.analiseList = this.items.filter(arr => arr.step === 'Em análise')
    this.protocolList = this.items.filter(arr => arr.step === 'Protocolo')
  },
  methods: {
    cardSelected(card) {
      this.itemToUpdate = { id: card.id, step: '' }
    },
    saveChanges(to) {
      this.itemToUpdate.step = to
      licensing.editLicensing(this.itemToUpdate).then(() => {
        this.toast({
          title: 'Atualizado!',
          text: 'Licenciamento atualizado',
          variant: 'success',
        })
        this.$emit('update-kanban')
      })
    },
    createLicensing() {
      this.$router.push('licenciamento/adicionar/detalhes')
    },
  },
}
</script>

<style lang="scss" scoped>
.kanban-card {
  background-color: rgba(160, 174, 185, 0.25);
  border-radius: 3px;
  border-color: none !important;
  padding: .7rem;
  max-height: 70vh;
  overflow: auto;
  &-outside {
    margin-bottom: .5rem;
  }
  &-inside {
    background-color: rgba(160, 174, 185, 0.13);
    border: none;
    margin-bottom: .5rem;
    border-radius: 3px;
    &-body {
      padding: .3rem .5rem;
    }
  }
}
.prazo-info {
  font-size: 13px !important;
}
.badge {
  border-radius: 5px;
}
////

.list-group-item {
  padding: 0;
  margin-bottom: .7rem;
  transition: all 1s;
  border: 0;
  border-radius: 4px
}
</style>
