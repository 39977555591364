<template>
  <div>
    <div
      v-if="$can('create', 'licensesconditions')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="createLicenseConditions()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <div class="d-flex align-items-center">
      <b-input-group class="input-group-merge round search-input-filter mr-3">
        <b-input-group-prepend
          is-text
        >
          <VSIcons
            name="Search"
            color="#7D8E9E"
            width="18"
            height="18"
          />
        </b-input-group-prepend>
        <b-form-input
          v-model="filterKanban"
          placeholder="Buscar..."
        />
      </b-input-group>
      <ModeViewAlternate module="license" />
    </div>
    <div
      id="kanban"
      class="kaban-view mt-4"
    >
      <div class="kaban-column">
        <h5>Vigente</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="vigenteList"
            @add="saveChanges('Vigente')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in vigenteList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="current"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!vigenteList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
      <div class="kaban-column">
        <h5>Vencida</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="vencidaList"
            @add="saveChanges('Vencida')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in vencidaList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="protocol"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!vencidaList.length > 0">
            <span

              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
      <div class="kaban-column">
        <h5>Renovação no prazo</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="renovacaoList"
            @add="saveChanges('Renovação dentro do prazo')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in renovacaoList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="renovacaoNoPrazo"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!renovacaoList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
      <div class="kaban-column">
        <h5>Renovação fora do prazo</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="renovacaoForaDoPrazoList"
            @add="saveChanges('Renovação fora do prazo')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in renovacaoForaDoPrazoList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="renovacaoForaPrazo"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!renovacaoForaDoPrazoList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
      <div class="kaban-column">
        <h5>Prorrogação no prazo</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="prorrogacaoList"
            @add="saveChanges('Prorrogação dentro do prazo')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in prorrogacaoList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="prorrogacaoNoPrazo"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!prorrogacaoList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
      <div class="kaban-column">
        <h5>Prorrogação fora do prazo</h5>
        <b-card
          body-class="kanban-card"
          class="border-0 rounded"
        >
          <draggable
            class="list-group list-group-flush cursor-move"
            tag="ul"
            group="kanban"
            :list="prorrogacaoForaDoPrazoList"
            @add="saveChanges('Prorrogação fora do prazo')"
          >
            <transition-group
              type="transition"
              name="list-enter-up"
            >
              <b-list-group-item
                v-for="card in prorrogacaoForaDoPrazoList"
                :key="card.id"
                tag="li"
              >
                <LicenseKanbanCard
                  v-if="card.licensing_conditions_name ? card.licensing_conditions_name.toLowerCase().indexOf(filterKanban.toLowerCase()) != -1 : true"
                  :card="card"
                  name="prorrogacaoForaPrazo"
                  @selected="cardSelected(card)"
                />
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-card v-if="!prorrogacaoForaDoPrazoList.length > 0">
            <span
              class="d-flex bg-light j-c-center text-secondary font-13"
            >Nenhum item.</span>
          </b-card>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BListGroupItem,
  VBToggle,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ModeViewAlternate from '@/components/mode-view-alternate/ModeViewAlternate.vue'
import VSIcons from '@/assets/icons/VSIcons.vue'
import LicenseKanbanCard from './LicenseKanbanCard.vue'
import license from '@/services/license'

export default {
  components: {
    BCard,
    BListGroupItem,
    draggable,
    LicenseKanbanCard,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    VSIcons,
    ModeViewAlternate,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      filterKanban: '',
      vigenteList: [],
      prorrogacaoList: [],
      renovacaoList: [],
      vencidaList: [],
      renovacaoForaDoPrazoList: [],
      prorrogacaoForaDoPrazoList: [],
      itemToUpdate: {},
    }
  },
  mounted() {
    this.vigenteList = this.items.filter(arr => arr.situation === 'Vigente')
    this.vencidaList = this.items.filter(arr => arr.situation === 'Vencida')
    this.renovacaoList = this.items.filter(arr => arr.situation === 'Renovação dentro do prazo')
    this.prorrogacaoList = this.items.filter(arr => arr.situation === 'Prorrogação dentro do prazo')
    this.renovacaoForaDoPrazoList = this.items.filter(arr => arr.situation === 'Renovação fora do prazo')
    this.prorrogacaoForaDoPrazoList = this.items.filter(arr => arr.situation === 'Prorrogação fora do prazo')
  },
  methods: {
    cardSelected(card) {
      this.itemToUpdate = { id: card.id, situation: '' }
    },
    saveChanges(to) {
      this.itemToUpdate.situation = to
      license.editLicensingConditions(this.itemToUpdate).then(() => {
        this.toast({
          title: 'Atualizado!',
          text: 'Licenciamento atualizado',
          variant: 'success',
        })
        this.$emit('update-kanban')
      })
    },
    createLicenseConditions() {
      this.$router.push('licenca/adicionar/detalhes')
    },
  },
}
</script>

<style lang="scss" scoped>

.kaban-view {
  display: flex;
  min-height: 100%;
  .kaban-column {
    width: 23.5%;
    margin-right: 1%;
  }
}

.kanban-card {
  background-color: rgba(160, 174, 185, 0.25);
  border-radius: 3px;
  border-color: none !important;
  padding: .7rem;
  max-height: 70vh;
  overflow: auto;
  &-outside {
    margin-bottom: .5rem;
  }
  &-inside {
    background-color: rgba(160, 174, 185, 0.13);
    border: none;
    margin-bottom: .5rem;
    border-radius: 3px;
    &-body {
      padding: .3rem .5rem;
    }
  }
}
.prazo-info {
  font-size: 13px !important;
}
.badge {
  border-radius: 5px;
}
////

.list-group-item {
  padding: 0;
  margin-bottom: .7rem;
  transition: all 1s;
  border: 0;
  border-radius: 4px
}
</style>
