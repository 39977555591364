<template>
  <div><Licensing /></div>
</template>

<script>
import Licensing from '@/modules/licensing/Licensing.vue'

export default {
  components: {
    Licensing,
  },
}
</script>

<style>

</style>
