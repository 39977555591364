<template>
  <div class="licensing-page">
    <div
      v-if="$can('create', 'licensing')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal()"
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <div class="d-flex align-items-center">
      <b-input-group class="input-group-merge round search-input-filter mr-3">
        <b-input-group-prepend
          is-text
        >
          <VSIcons
            name="Search"
            color="#7D8E9E"
            width="18"
            height="18"
          />
        </b-input-group-prepend>
        <b-form-input
          id="filter-input"
          v-model="filter"
          placeholder="Buscar..."
          type="search"
        />
      </b-input-group>
      <ModeViewAlternate module="licensing" />
    </div>
    <b-table
      id="licensing-table"
      class="module-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailItemTable"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/licensing/general', 'Licensing')"
        />
      </template>
      <template
        v-if="$can('delete', 'licensing')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/licensing/general', fetchTable: 'Licensing' })"
        />
      </template>
      <template #cell(licensing_code)="row">
        {{ row.item.licensing_code || 'Não informado' }}
      </template>
      <template #cell(licensing_name)="row">
        {{ row.item.licensing_name || 'Não informado' }}
      </template>
      <template #cell(project)="row">
        {{ row.item.project && row.item.project ? (row.item.project.project_name || row.item.project.full_name) : 'Não informado' }}
      </template>
      <template #cell(company)="row">
        {{ row.item.project && row.item.project.company ?  (row.item.project.company.nome_fantasia || row.item.project.company.full_name) : 'Não informado' }}
      </template>
      <template #cell(licensingAgency)="row">
        {{ row.item.project && row.item.licensingAgency.acronyms || 'Não informado' }}
      </template>
      <template #cell(license_type)="row">
        {{ row.item.project && row.item.licenseType.subject || 'Não informado' }}
      </template>
      <template #cell(conclusion_deadline)="row">
        <b-badge
          v-if="row.item.step === 'Concluído'"
          variant="primary"
          class="p-2"
        >
          <span class="font-12">
            {{ 'Concluído' }}
          </span>
        </b-badge>
        <b-badge
          v-else
          :variant="variantPercentageDeadlineVariant(row.item.conclusion_deadline, regressiveCount(row.item.conclusion_date))"
        >
          <div class="d-flex align-items-center">
            <VSIcons
              name="Clock"
              height="22"
              :color="variantPercentageDeadlineColor(row.item.conclusion_deadline, regressiveCount(row.item.conclusion_date))"
            />
            <span class="prazo-info text-light">{{ moment(row.item.conclusion_date).fromNow() }}</span>
          </div>
        </b-badge>
      </template>
      <template #cell(progress)="row">
        <b-badge :variant="row.item.progress | variantProgess">
          <div class="d-flex align-items-center">
            <VSIcons
              name="TrendUpFill"
              height="22"
            />
            <span class="prazo-info text-white">{{ row.item.progress }}%</span>
          </div>
        </b-badge>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="enterprise-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BSpinner,
  BPagination,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import moment from 'moment'
import VSIcons from '@/assets/icons/VSIcons.vue'
import ModeViewAlternate from '@/components/mode-view-alternate/ModeViewAlternate.vue'
import VSModal from '@/components/modals/VSModal.vue'
import {
  variantProgess,
  variantPercentageDeadlineVariant,
  variantPercentageDeadlineColor,
} from '@/@core/utils/filter'

export default {
  components: {
    BTable,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    ModeViewAlternate,
    VSModal,
  },
  filters: {
    variantProgess,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      moment,
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Tipo',
          key: 'process_type',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Código',
          key: 'licensing_code',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'licensing_name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empresa',
          key: 'company',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empreendimentos',
          key: 'project',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Órgão',
          key: 'licensingAgency',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Licença',
          key: 'license_type',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Prazo Regressivo',
          key: 'conclusion_deadline',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Progresso',
          key: 'progress',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
  },
  methods: {
    variantPercentageDeadlineVariant,
    variantPercentageDeadlineColor,
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    createLicensing() {
      this.$router.push('licenciamento/adicionar/detalhes')
    },
    openModal() {
      this.showModal('Import')
    },
  },
}
</script>
<style lang="scss" scoped>
.prazo-info {
  font-size: 13px !important;
}
.badge {
  border-radius: 5px;
}
</style>
