<template>
  <div>
    <b-dropdown
      v-if="$can('update', permissionModule)"
      class="border-dropdown"
      no-caret
      menu-class="dropdown-menu-list-kanban"
      toggle-class="button-drop-toggle-list-kanban"
      variant="primary"
    >
      <template #button-content>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <VSIcons
              class="mr-2"
              :name="module === 'licensing' ? licensingView === 'table' ? 'List' : 'Kanban' : licenseView === 'table' ? 'List' : 'Kanban'"
              color="#000"
              width="17"
              height="16"
            />{{ module === 'licensing' ? licensingView === 'table' ? 'Lista' : 'Kanban' : licenseView === 'table' ? 'Lista' : 'Kanban' }}
          </div>
          <VSIcons
            class="ml-2 align-end-flex"
            name="ArrowDownHeader"
            color="#A0AEB9"
            width="14"
            height="14"
          />
        </div>
      </template>
      <b-dropdown-item-button
        class="dropdown-table-item mb-2"
        aria-describedby="dropdown-header-1"
        @click="setLicensingView('kanban')"
      >
        <div class="d-flex">
          <VSIcons
            class="mr-2"
            name="Kanban"
            color="#000"
            width="14"
            height="14"
          />Kanban
        </div>
      </b-dropdown-item-button>
      <b-dropdown-item-button
        class="dropdown-table-item"
        aria-describedby="dropdown-header-1"
        @click="setLicensingView('table')"
      >
        <div class="d-flex">
          <VSIcons
            class="mr-2"
            name="List"
            color="#000"
            width="17"
            height="16"
          />Lista
        </div>
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>
<script>
import {
  BDropdown,
  BDropdownItemButton,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BDropdown,
    BDropdownItemButton,
    VSIcons,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ licensingView: 'licensing/getLicensingModeView', licenseView: 'licensingConditions/getLicenseModeView' }),
    permissionModule() {
      if (this.module === 'license') { return 'licensesconditions' } return 'licensing'
    },
  },
  methods: {
    ...mapMutations({
      setItensToDelete: 'app/SET_ITENS_DELETE',
      setLicensingModeView: 'licensing/SET_LICENSING_MODE_VIEW',
      setLicenseModeView: 'licensingConditions/SET_LICENSE_MODE_VIEW',
    }),
    setLicensingView(value) {
      if (this.module === 'license') {
        localStorage.setItem('mode-license', value)
        this.setLicenseModeView(value)
      } else {
        localStorage.setItem('mode-licensing', value)
        this.setLicensingModeView(value)
      }
    },
  },
}
</script>
