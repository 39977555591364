<template>
  <div><License /></div>
</template>

<script>
import License from '@/modules/license/License.vue'

export default {
  components: {
    License,
  },
}
</script>

<style>

</style>
