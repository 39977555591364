<template>
  <b-card
    v-b-toggle="`collapse-${name}-${card.id}`"
    class="border-0"
    @mouseenter="$emit('selected')"
  >
    <b-card
      class="kanban-card-inside"
      body-class="kanban-card-inside-body"
    >
      <div class="font-12 font-weight-bold d-block">
        Nome
      </div>
      <div class="font-12 font-weight-500 text-secondary">
        {{ card.licensing_name || 'Não informado' }}
      </div>
    </b-card>
    <b-collapse :id="`collapse-${name}-${card.id}`">
      <b-card
        class="kanban-card-inside"
        body-class="kanban-card-inside-body"
      >
        <div class="font-12 font-weight-bold d-block">
          Empresa
        </div>
        <div class="font-12 font-weight-500 text-secondary">
          {{ card.project.company.nome_fantasia }}
        </div>
      </b-card>
      <b-card
        class="kanban-card-inside"
        body-class="kanban-card-inside-body"
      >
        <div class="font-12 font-weight-bold d-block">
          Conclusão
        </div>
        <div class="font-12 font-weight-500 text-secondary">
          {{ moment(card.conclusion_date).format('DD/MM/YYYY') }}
        </div>
      </b-card>

      <b-card
        class="kanban-card-inside"
        body-class="kanban-card-inside-body"
      >
        <div class="font-12 font-weight-bold d-block">
          Empreendimento
        </div>
        <div class="font-12 font-weight-500 text-secondary">
          {{ card.project.project_name }}
        </div>
      </b-card>
      <b-card
        class="kanban-card-inside"
        body-class="kanban-card-inside-body"
      >
        <div class="font-12 font-weight-bold d-block">
          Número do processo
        </div>
        <div class="font-12 font-weight-500 text-secondary">
          {{ card.process_number }}
        </div>
      </b-card>
      <div class="d-flex justify-content-between">
        <b-card
          class="kanban-card-inside w-50 mr-2"
          body-class="kanban-card-inside-body"
        >
          <div class="font-12 font-weight-bold d-block">
            Órgão
          </div>
          <div class="font-12 font-weight-500 text-secondary">
            {{ card.licensingAgency.acronyms }}
          </div>
        </b-card>
        <b-card
          class="kanban-card-inside w-50"
          body-class="kanban-card-inside-body"
        >
          <div class="font-12 font-weight-bold d-block">
            Licença
          </div>
          <div class="font-12 font-weight-500 text-secondary">
            {{ card.licenseType.acronyms }}
          </div>
        </b-card>
      </div>
    </b-collapse>
    <div class="line-divider" />
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <b-badge
          :variant="variantPercentageDeadlineVariant(card.conclusion_deadline, regressiveCount(card.conclusion_date))"
          class="mr-2 text-white"
        >
          <div class="d-flex align-items-center">
            <VSIcons
              name="Clock"
              height="22"
              :color="variantPercentageDeadlineColor(card.conclusion_deadline, regressiveCount(card.conclusion_date))"
            />
            <span class="prazo-info">{{ moment(card.conclusion_date).fromNow() }}</span>
          </div>
        </b-badge>

        <b-badge :variant="card.progress | variantProgess">
          <div class="d-flex align-items-center">
            <VSIcons
              name="TrendUpFill"
              color="white"
              width="22"
              height="22"
            />
            <span class="prazo-info text-white">{{ card.progress }}%</span>
          </div>
        </b-badge>
      </div>
      <div
        v-b-popover.hover="{ variant: 'primary', content: 'Mais detalhes, clique aqui.' }"
        class="font-12 font-weight-500 text-secondary"
      >
        <router-link :to="`/licenciamento/${card.id}/detalhes`">
          <span class="text-center font-12 d-flex align-items-center">
            <VSIcons
              class="ml-1"
              name="Export2"
              color="#18ac21"
              width="14"
              height="14"
            />
          </span>
        </router-link>
      </div>

    </div>
  </b-card>
</template>
<script>
import {
  BCard, BBadge, BCollapse, VBToggle, VBPopover,
} from 'bootstrap-vue'
import moment from 'moment'
import VSIcons from '@/assets/icons/VSIcons.vue'
import {
  variantProgess, variantPercentageDeadlineVariant,
  variantPercentageDeadlineColor,
} from '@/@core/utils/filter'

export default {
  components: {
    BCard, BBadge, BCollapse, VSIcons,
  },
  filters: {
    variantProgess,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-popover': VBPopover,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      moment,
      variantPercentageDeadlineVariant,
      variantPercentageDeadlineColor,
    }
  },
}
</script>
<style lang="scss" scoped>
.kanban-card {
  background-color: rgba(160, 174, 185, 0.25);
  border-radius: 3px;
  border-color: none !important;
  padding: .7rem;
  max-height: 70vh;
  overflow: auto;
  &-outside {
    margin-bottom: .5rem;
  }
  &-inside {
    background-color: rgba(160, 174, 185, 0.13);
    border: none;
    margin-bottom: .5rem;
    border-radius: 3px;
    &-body {
      padding: .3rem .5rem;
    }
  }
}
.prazo-info {
  font-size: 13px !important;
}
.badge {
  border-radius: 5px;
}
</style>
