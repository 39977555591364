<template>
  <div class="license-conditions-page">
    <div>
      <LicenseTable
        v-if="licensingView === 'table'"
        :items="items"
      />
      <LicenseKanban
        v-if="licensingView === 'kanban' && !kanbanApiInCall"
        :items="items"
        @update-kanban="fetchLicensingConditions()"
      />
      <b-spinner
        v-else-if="kanbanApiInCall"
        variant="primary"
        class="general-data-spinner"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LicenseTable from './LicenseTable.vue'
import LicenseKanban from '@/modules/license/LicenseKanban.vue'

export default {
  components: {
    LicenseTable,
    LicenseKanban,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      items: 'licensingConditions/getLicensingConditions', licensingView: 'licensingConditions/getLicenseModeView', kanbanApiInCall: 'licensingConditions/getLicensingConditionsApiInCall',
    }),
  },
  created() {
    this.setApiInCall(true)
    this.fetchAndActivateCondAndActTab(false)
    this.fetchLicensingConditions().then(() => {
      if (localStorage.getItem('mode-license')) {
        this.setLicenseView(localStorage.getItem('mode-license'))
      } else {
        this.setLicenseView('table')
      }
    })
  },
  methods: {
    ...mapActions({
      fetchLicensingConditions: 'licensingConditions/fetchLicensingConditions',
      fetchAndActivateCondAndActTab: 'licensingConditions/fetchAndActivateCondAndActTab',
    }),
    ...mapMutations({
      setApiInCall: 'licensingConditions/SET_API_LICENSING_CONDITIONS_IN_CALL',
      setItensToDelete: 'app/SET_ITENS_DELETE',
      setLicenseView: 'licensingConditions/SET_LICENSE_MODE_VIEW',
    }),
  },
}
</script>
<style lang="scss" scoped>

</style>
