<template>
  <div class="license-page">
    <div
      v-if="$can('create', 'licensesconditions')"
      class="btn-actions-content"
    >
      <b-button
        class="btn-grad d-flex align-items-center"
        @click="openModal() "
      >
        <VSIcons
          name="AddSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span class="align-middle">Adicionar</span>
      </b-button>
    </div>
    <div class="d-flex align-items-center">
      <b-input-group class="input-group-merge round search-input-filter mr-3">
        <b-input-group-prepend
          is-text
        >
          <VSIcons
            name="Search"
            color="#7D8E9E"
            width="18"
            height="18"
          />
        </b-input-group-prepend>
        <b-form-input
          id="filter-input"
          v-model="filter"
          placeholder="Buscar..."
          type="search"
        />
      </b-input-group>
      <ModeViewAlternate module="license" />
    </div>
    <b-table
      id="license-table"
      class="module-table mt-4"
      :items="items"
      :fields="fields"
      hover
      responsive
      details-td-class="align-middle"
      :busy="apiInCall"
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      :filter="filter"
      :filter-included-fields="filterOn"
      empty-filtered-text="Não há resultados para sua pesquisa."
      empty-text="Não há registros para serem exibidos."
      show-empty
      @filtered="onFiltered"
      @row-selected="detailItemTable"
    >
      <template #table-busy>
        <b-spinner variant="primary" />
      </template>
      <template
        v-if="$can('delete', 'licensesconditions')"
        #head(select)
      >
        <b-form-checkbox
          v-model="allSelected"
          :indeterminate="indeterminate"
          @change="toggleAll('system/licensing-conditions/general', 'LicenseCondiotions')"
        />
      </template>
      <template
        v-if="$can('delete', 'licensesconditions')"
        #cell(select)="row"
      >
        <b-form-checkbox
          v-model="selected"
          :value="row.item"
          @change="setItensToDelete({ itens: selected, api: 'system/licensing-conditions/general', fetchTable: 'LicenseCondiotions' })"
        />
      </template>
      <template #cell(licensing_conditions_code)="row">
        {{ row.item.licensing_conditions_code || 'Não informado' }}
      </template>
      <template #cell(licensing_conditions_name)="row">
        {{ row.item.licensing_conditions_name || 'Não informado' }}
      </template>
      <template #cell(project)="row">
        {{ row.item.project && row.item.project ? (row.item.project.project_name || row.item.project.full_name) : 'Não informado' }}
      </template>
      <template #cell(company)="row">
        {{ row.item.project && row.item.project.company ?  (row.item.project.company.nome_fantasia || row.item.project.company.full_name) : 'Não informado' }}
      </template>
      <template #cell(licensingAgency)="row">
  {{ row.item.project && row.item.licensingAgency && row.item.licensingAgency.acronyms ? row.item.licensingAgency.acronyms : 'Não informado' }}
</template>
<template #cell(licenseType)="row">
  {{ row.item.project && row.item.licenseType && row.item.licenseType.acronyms ? row.item.licenseType.acronyms : 'Não informado' }}
</template>
      <template #cell(alert)="row">
        <div
          v-if="handleAlertRenewal(row.item.renewal_date, row.item.renewal_alert)"
          class="d-flex align-items-center justify-content-left"
        >
          <div
            v-b-tooltip.hover
            class="alert-neon neon-effect mr-2 d-flex align-items-center justify-content-center"
            :title="`Alerta de renovação da Licença. ${formatLocalDate(row.item.renewal_date)}`"
          >
            <span class="text-light">!</span>
          </div>
          <span class="font-11 font-weight-normal text-left">Data de renovação {{ formatLocalDate(row.item.renewal_date) }}</span>
        </div>
      </template>
      <template #cell(expiration_date)="row">
        <div class="d-flex align-items-center justify-content-center">
          <span class="validade">{{ row.item.expiration_date | formatLocalDate }}</span>
          <div>
            <VSIcons
              name="calendar"
              height="18"
            />
          </div>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="enterprise-table"
    >
      <template #prev-text>
        <VSIcons name="ArrowLeft" />
      </template>
      <template #next-text>
        <VSIcons name="ArrowRight" />
      </template>
    </b-pagination>
    <VSModal
      width="500"
      name="Import"
      :draggable="false"
    />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BSpinner,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import { tableConfig } from '@core/mixins/ui/forms'
import VSIcons from '@/assets/icons/VSIcons.vue'
import ModeViewAlternate from '@/components/mode-view-alternate/ModeViewAlternate.vue'
import { formatLocalDate } from '@/@core/utils/filter'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    BTable,
    VSIcons,
    BFormCheckbox,
    BSpinner,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    ModeViewAlternate,
    VSModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatLocalDate,
  },
  mixins: [
    tableConfig,
  ],
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          label: '',
          key: 'select',
        },
        {
          label: 'Código',
          key: 'licensing_conditions_code',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Nome',
          key: 'licensing_conditions_name',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empreendimento',
          key: 'project',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Empresa',
          key: 'company',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Órgão',
          key: 'licensingAgency',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Licença',
          key: 'licenseType',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Validade',
          key: 'expiration_date',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Situação',
          key: 'situation',
          sortable: true,
          tdClass: 'align-middle to-uppercase',
        },
        {
          label: 'Alerta de Renovação',
          key: 'alert',
          tdClass: 'align-middle to-uppercase',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ apiInCall: 'app/getApiInCall' }),
  },
  methods: {
    ...mapMutations({ setItensToDelete: 'app/SET_ITENS_DELETE' }),
    formatLocalDate,
    createLicenseConditions() {
      this.$router.push('licenca/adicionar/detalhes')
    },
    handleAlertRenewal(date, alert) {
      // eslint-disable-next-line
      Date.prototype.addDays = function (days) {
        const dateAdd = new Date(this.valueOf())
        dateAdd.setDate(dateAdd.getDate() - days + 1)
        return dateAdd
      }
      return new Date(date).addDays(alert) <= new Date()
    },
    openModal() {
      this.showModal('Import')
    },
  },
}
</script>
<style lang="scss" scoped>
.validade {
font-weight: 600;
font-size: 13px;
line-height: 16px;
text-align: center;
letter-spacing: 0.75px;
color: #33383C;
margin-right: 5px;
display: block;

}
</style>
