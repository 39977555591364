<template>
  <div class="enterprise-page">
    <div>
      <LicensingTable
        v-if="licensingView === 'table'"
        :items="items"
      />
      <Licensingkanban
        v-if="licensingView === 'kanban' && !kanbanApiInCall"
        :items="items"
        @update-kanban="fetchLicensing()"
      />
      <b-spinner
        v-else-if="kanbanApiInCall"
        variant="primary"
        class="general-data-spinner"
      />
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LicensingTable from './LicensingTable.vue'
import Licensingkanban from './LicensingKanban.vue'

export default {
  components: {
    LicensingTable,
    Licensingkanban,
    BSpinner,
  },
  computed: {
    ...mapGetters({
      items: 'licensing/getLicensing', licensingView: 'licensing/getLicensingModeView', kanbanApiInCall: 'licensing/getLicensingApiInCall',
    }),
  },
  created() {
    this.setApiInCall(true)
    this.fetchAndActivateDocsAndActTab(false)
    this.fetchLicensing().then(() => {
      if (localStorage.getItem('mode-licensing')) {
        this.setLicensingView(localStorage.getItem('mode-licensing'))
      } else {
        this.setLicensingView('table')
      }
    })
  },
  methods: {
    ...mapActions({ fetchLicensing: 'licensing/fetchLicensing', fetchAndActivateDocsAndActTab: 'licensing/fetchAndActivateDocsAndActTab' }),
    ...mapMutations({
      setApiInCall: 'licensing/SET_API_LICENSING_IN_CALL',
      setItensToDelete: 'app/SET_ITENS_DELETE',
      setLicensingView: 'licensing/SET_LICENSING_MODE_VIEW',
    }),
  },
}
</script>
<style lang="scss" scoped>

</style>
